import axios, { AxiosResponse } from "axios";
import { SaveSearchResponse, SearchFilters, SearchRequestResult, Sort } from "../models/search";
import { SavedSearchesResult, SavedSearch } from "../models/savedSearch";
import { UserState } from "../components/Authorization/AuthorizationState";
import { buildHeaders } from "./utils";

const API_URI = import.meta.env.VITE_APP_API_URI;

export const getVerbatims = async (
  filters: SearchFilters,
  sort: Sort | null,
  start: number | null = 0,
  pageSize: number | null = 100,
  user: UserState,
): Promise<SearchRequestResult> => {
  if (!API_URI) throw new Error("API URI env variable not set.");
  if (start && start < 0) throw new Error("Start must be >= 0.");
  if (pageSize && pageSize < 0) throw new Error("Page size must be >= 0.");

  // putting sort state back into filter state
  const postBody: SearchFilters = sort != null ? { ...filters, sort: sort } : filters;

  const response = await axios.post(
    `${API_URI}/search?start=${Math.floor(start || 0)}&page-size=${Math.floor(pageSize || 0)}`,
    postBody,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const saveSearch = async (title: string, searchFilters: SearchFilters, user: UserState) => {
  const { query, base_filters, visual_filters, sort, semantic, dates, dynamic_date_range } = searchFilters;
  const payload = {
    title,
    base_filters: {
      ...base_filters,
    },
    visual_filters: {
      ...visual_filters,
    },
    query,
    dates,
    dynamic_date_range,
    sort,
    semantic,
  };
  const response = await axios.post<SaveSearchResponse>(`${API_URI}/searches`, payload, {
    headers: buildHeaders(user),
  });
  return response.data;
};

export const deleteSavedSearch = async (savedSearchId: string, user: UserState): Promise<string> => {
  const response = await axios.delete(`${API_URI}/searches/${savedSearchId}`, {
    headers: buildHeaders(user),
  });
  return response.data;
};

export const getSavedSearches = async (user: UserState): Promise<SavedSearch[]> => {
  const response: AxiosResponse<SavedSearchesResult> = await axios.get(`${API_URI}/searches`, {
    headers: buildHeaders(user),
  });
  return response.data.saved_searches;
};

const getFileNameFromContentDisposition = (contentDisposition: string) => {
  if (!contentDisposition) return null;

  const match = contentDisposition.match(/filename="?([^"]+)"?/);

  return match ? match[1] : null;
};

export const getSearchExport = async (searchFilters: SearchFilters, user: UserState, columns?: string[]) => {
  return axios
    .post(
      `${API_URI}/search/export_custom`,
      {
        search: searchFilters,
        // TODO: Possibly a bug, passing empty array exports no columns (I thought I accounted for this API side)
        columns: columns?.length ? columns : [],
      },
      {
        responseType: "blob",
        headers: buildHeaders(user),
      },
    )
    .then((response) => {
      const fileName = getFileNameFromContentDisposition(response.headers["content-disposition"]);
      const href = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName || "verbatims.csv");
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};
