import { RouteId } from "../../RouteId";

const Roles = {
  MEDICAL: "medical",
  MED_INFO: "med_info",
  COMMERCIAL: "commercial",
  USLT_RETRO: "uslt_retro",
  C360: "C360",
  CMG_HEALTHCARE_LAW: "cmg_healthcare_law",
  IT_CMG: "it_cmg",
  PRIMARY_MARKET_RESEARCH: "pmr",
};

// NOTE: The first page in this list will be treated at the accounts "Home Page" for purposes of redirecting and logging in
export const PageAccessList = [
  {
    domain: Roles.MEDICAL,
    pages: [
      RouteId.Deepchat.Root,
      RouteId.SalesData.Root,
      RouteId.Explore.Root,
      RouteId.MarketResearch.Root,
      RouteId.Dashboards.Root,
    ],
  },
  {
    domain: Roles.COMMERCIAL,
    pages: [
      RouteId.Deepchat.Root,
      RouteId.SalesData.Root,
      RouteId.Explore.Root,
      RouteId.MarketResearch.Root,
      RouteId.Dashboards.Root,
    ],
  },
  {
    domain: Roles.USLT_RETRO,
    pages: [RouteId.Retros.Root],
  },
  {
    domain: Roles.CMG_HEALTHCARE_LAW,
    pages: [
      RouteId.Deepchat.Root,
      RouteId.SalesData.Root,
      RouteId.Explore.Root,
      RouteId.Dashboards.Root,
      RouteId.Retros.Root,
    ],
  },
  {
    domain: Roles.IT_CMG,
    pages: [RouteId.Deepchat.Root, RouteId.SalesData.Root, RouteId.Explore.Root, RouteId.Dashboards.Root],
  },
  {
    domain: Roles.PRIMARY_MARKET_RESEARCH,
    pages: [RouteId.MarketResearch.Root],
  },
];

export default Roles;
