import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUserNavigation from "../../useUserNavigation";
import { isTokenExpired } from "../../utils/auth/isTokenExpired";
import { DefaultUserState } from "./AuthorizationState";
import useAuthorizationManager from "./useAuthorizationManager";
import useUserContext from "./useUserContext";

const pingUrl = import.meta.env.VITE_APP_PING_URL;
const endSessionUrl = import.meta.env.VITE_APP_PING_END_SESSION_URL;

type AuthenticationProps = {
  children: React.ReactNode;
};

const Authentication = ({ children }: AuthenticationProps) => {
  const AuthorizationManager = useAuthorizationManager();

  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserContext();

  const { hasAccessToCurrentRoute, navigateToHomepage } = useUserNavigation();

  useEffect(() => {
    const { UserState } = AuthorizationManager;

    if (UserState.intent == "logout") {
      AuthorizationManager.clearSession();

      window.location.replace(endSessionUrl);
    }

    if (UserState.intent == "select-role") {
      // Unset the current user's selected roles
      AuthorizationManager.setUserState((_) => ({
        ..._,
        selectedRoles: [],
        isLoading: true,

        // Make sure to clear the intent
        intent: undefined,
      }));

      // Send the user to the role selection page
      navigate("/auth");
    }
  }, [AuthorizationManager.UserState.intent]);

  // catch the case where user is not authorized to view the application
  if (location.pathname.includes("auth") && location.search.includes("error")) {
    throw new Error("You are not authorized to view this application.");
  }

  // If in auth flow, let content pass through
  if (["auth", "error", "logout"].some((path) => location.pathname.includes(path))) {
    return children;
  }

  if (!user.isLoading && !isTokenExpired(user.token)) {
    // Route the user to their target homepage based on their role if they hit the app root "/"
    if (location.pathname === "/") {
      navigateToHomepage();
    }

    // If the user is trying to access a page they don't have access to
    if (!hasAccessToCurrentRoute()) {
      console.warn(`User does not have access to: ${location.pathname}, Redirecting to homepage`);
      navigateToHomepage();
    }

    return children;
  }
  // If the user is in a fully expired state, return them to the login screen
  else if (isTokenExpired(user.token)) {
    AuthorizationManager.clearSession();
    AuthorizationManager.setUserState(DefaultUserState);

    window.location.replace(pingUrl);

    return <></>;
  }

  return children;
};

export default Authentication;
