import { atom, useAtom } from "jotai";
import { SearchFilters } from "../models/search";
import { useEffect } from "react";
import { defaultFilterState } from "../constants/defaultFilterState";
import useUserContext from "../components/Authorization/useUserContext";

const DefaultSearchModelAtom = atom<SearchFilters | undefined>(undefined);

export const useSearchStore = () => {
  const user = useUserContext();
  const userRoles = user.selectedRoles;

  const [defaultSearch, setDefaultSearch] = useAtom(DefaultSearchModelAtom);

  const applyDefaultSearchState = () => {
    useEffect(() => {
      // We want to turn off stack_related by default when logged in as a USLT user
      const role_override = { stack_related: !userRoles.includes("uslt_retro") };

      setDefaultSearch({ ...defaultFilterState, ...role_override });
    }, []);
  };

  return {
    defaultSearch,
    setDefaultSearch,
    applyDefaultSearchState,
  };
};
