import { atom, useAtom } from "jotai";
import { callStartSummarizeDocAPI, getPreSignedS3Url, uploadDocumentToS3 } from "../../api/dataSources";
import useUserContext from "../../components/Authorization/useUserContext";
import { TCompressionLevel } from "../../components/Deepchat/models";
import { SummarySettingsModalOpenAtom } from "../../components/Deepchat/SummarizeDocument/SummarizeDocumentState";

export const loadingAtom = atom(false);
export const fileAtom = atom<File | null>(null);
export const compressionLevelAtom = atom<TCompressionLevel[]>([
  { id: "low", value: "Low", isSelected: false },
  { id: "medium", value: "Medium", isSelected: false },
  { id: "high", value: "High", isSelected: false },
  { id: "low,medium,high", value: "All", isSelected: true },
]);

export const useFileUploadPanelStore = () => {
  const user = useUserContext();
  const [loading, setLoading] = useAtom(loadingAtom);
  const [, setOpen] = useAtom(SummarySettingsModalOpenAtom);
  const [file, setFile] = useAtom(fileAtom);
  const [compressionArray, setCompressionArray] = useAtom(compressionLevelAtom);

  const callAPI = async () => {
    setLoading(true);
    try {
      const response = await getPreSignedS3Url(user, file?.name ?? "");

      if (response.s3_document_url) {
        const s3Response = await uploadDocumentToS3(response.s3_document_url, file!);
        if (s3Response) {
          let array: string[];
          compressionArray.forEach((item) => {
            if (item.isSelected) {
              if (item.value === "All") {
                array = ["low", "medium", "high"];
              } else {
                array = [item.id];
              }
            }
          });

          const json = {
            document_id: response.document_id,
            compression_level: array!,
          };
          const test = await callStartSummarizeDocAPI(user, json);
        } else {
          throw new Error("Failed to upload document to s3");
        }
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  const updateCompressionArray = (item: TCompressionLevel) => {
    const newArray = [...compressionArray];
    newArray.forEach((element) => {
      element.isSelected = false;
      if (element.id === item.id) {
        element.isSelected = true;
      }
    });
    console.log(newArray);
    setCompressionArray(newArray);
  };

  return {
    loading,
    setOpen,
    file,
    setFile,
    compressionArray,
    updateCompressionArray,
    callAPI,
  };
};
