import { AutoAwesomeOutlined, DocumentScannerOutlined } from "@mui/icons-material";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";
import useMarketResearchStore from "../useMarketResearchStore";
import InterviewTranscript from "./InterviewTranscript";

export default function ViewTranscript() {
  const { getStudy, studyId, transcripts, isLoadingTranscript } = useMarketResearchStore();
  const study = studyId ? getStudy(studyId) : undefined;
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: `${TOP_BAR_HEIGHT}px`,
        padding: "24px",
        overflow: "hidden",
        display: "flex",
        background: "#F5F5F9",
      }}
    >
      {isLoadingTranscript ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "75%" }}>
            <Box sx={{ height: "100%", overflow: "auto" }} className="StudyChat-Scroll">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  flexDirection={"row"}
                >
                  <Typography variant="h5" sx={{ color: "#004677" }}>
                    {study?.study_title}
                  </Typography>
                  <Link to={"/market-research"} style={{ textDecoration: "none" }}>
                    CHANGE STUDY
                  </Link>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", mt: "2rem", gap: "0.25rem" }}>
                <Box>
                  <AutoAwesomeOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
                  <Typography variant="h6" color={"#13294B"} display="inline-block">
                    Deepsense Summary
                  </Typography>
                </Box>
                <Card sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Box sx={{ padding: "1rem" }}>{transcripts?.transcript}</Box>
                </Card>
                <Box>
                  <DocumentScannerOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
                  <Typography variant="h6" color={"#13294B"} display="inline-block">
                    Transcript
                  </Typography>
                </Box>
                <Card sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Box sx={{ padding: "1rem" }}>{transcripts?.transcript_summary}</Box>
                </Card>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              ml: "2em",
              flex: 1,
              display: "flex",
              borderLeft: "1px solid #eee",
              overflow: "hidden",
              flexDirection: "column",
              paddingX: "2rem",
            }}
          >
            <InterviewTranscript />
          </Box>
        </>
      )}
    </Box>
  );
}
