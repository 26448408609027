import { useCallback } from "react";
import useWebSocket from "react-use-websocket";
import * as DeepchatApi from "../../api/deepchat";
import { WebSocketChatResponse } from "../../contexts/useDeepchat";

import useUserContext from "../../components/Authorization/useUserContext";

const DEEPCHAT_WS_URI = import.meta.env.VITE_APP_DEEPCHAT_WS_URI;

export default function useMarketResearchSocket() {
  const user = useUserContext();

  const formatChatUrl = (chatAuthCode: string) => {
    return `${DEEPCHAT_WS_URI}?authCode=${chatAuthCode}`;
  };

  const getChartUrl = useCallback(async () => {
    const response = await DeepchatApi.getToken(user);
    return formatChatUrl(response.auth_code);
  }, []);

  const getWebSocket = useCallback(
    () =>
      useWebSocket<WebSocketChatResponse<unknown>>(getChartUrl, {
        shouldReconnect: () => true,
      }),
    [0],
  );
  const { lastJsonMessage, sendJsonMessage, readyState } = getWebSocket();

  return {
    lastJsonMessage,
    sendJsonMessage,
    readyState,
  };
}
