import { atom } from "jotai";
import { SearchBase, SearchFilters } from "../../models/search";

export interface MarketResearchStudy {
  num_respondents: number;
  study_date: string; // "2024-04-23"
  study_key: string; // "venclexta";
  study_title: string; // "Venclexta Combination Qualitative Research";
}

export interface StudyQuestion {
  question_id: string;
  question_title: string;
  responses: number;
}

export interface ChatInteraction {
  source_ids: string[];

  search_object?: SearchFilters;
  timestamp?: number;
  user_chat_session: string; //UUID;

  user_id: string;
  user_query?: string;

  answer?: string;
  complete?: boolean;
}

//{"type":"chat.source","data":{"record_id":"m8KDlpMBn2rDudtZMaTn"}}
export interface PmrDataSource {
  record_id: string; // m8KDlpMBn2rDudtZMaTn
}

export interface PmrChatRequest {
  search_request: SearchBase;
  user_chat_session?: string | null;
  query: string;
}

export type MarketResearchChatStateType = {
  studyId?: string;
  question: string; // free text input state variable
  isLoading: boolean;
  messages: ChatInteraction[];
  respondentId?: string;
  filterJson: Record<string, string[] | undefined>;
};

export const MarketResearchStateAtom = atom<MarketResearchChatStateType>({
  question: "",
  isLoading: false,
  messages: [],
  filterJson: {},
  respondentId: "",
});

export interface MarketResearchDrawerState {
  open: boolean;
}

export const MarketResearchDrawerStateAtom = atom<MarketResearchDrawerState>({
  open: false,
});

export const FocusedInteraction = atom<ChatInteraction | undefined>(undefined);

export type TMRTranscripts = {
  respondent_id: number;
  persona: string;
  target_priority?: string;
  specialty: string;
  practice_segment: string;
  heir: string;
  transcript: string;
  transcript_summary: string;
};
