import { Download, MoreVert, ViewColumn, VisibilityOutlined } from "@mui/icons-material";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Typography as T, Tooltip } from "@mui/material";
import { closeSnackbar } from "notistack";
import { useState } from "react";
import { getSearchExport } from "../../../api/newSearch";
import { StyledIconButton } from "../../../components/common";
import { useFilterContext } from "../../../contexts/FilterProvider";
import emitSnackbar from "../../../emitSnackbar";

import { useAtom } from "jotai";
import useUserContext from "../../Authorization/useUserContext";
import { ColumnSelectModalOpen } from "./state/ColumnSelectModalOpen";
import { GridColumnVisibility } from "./state/GridColumnVisibility";
import { VerbatimColumns } from "./state/VerbatimColumns";

const DownloadMenuItem = () => {
  const user = useUserContext();
  const { filterState } = useFilterContext();

  const [columns] = useAtom(VerbatimColumns);
  const [columnVisibilityModel] = useAtom(GridColumnVisibility);

  let columnsToExport: string[] = [];
  if (Object.entries(columnVisibilityModel).length) {
    columns.forEach((col) => {
      // If the column does not exist in the visibility model OR it does AND its set to true, add the column export
      if (!Object.keys(columnVisibilityModel).includes(col.field) || columnVisibilityModel[col.field]) {
        columnsToExport.push(col.field);
      }
    });

    // If all columns are in the export list, we can pass an empty list for all column export
    if (columnsToExport.length == columns.length) {
      columnsToExport = [];
    }
  }

  const triggerDownload = async () => {
    if (!filterState) return;

    const snackbarKey = emitSnackbar("One moment, preparing download.", "info", { autoHideDuration: 30000 });
    try {
      await getSearchExport(filterState, user, columnsToExport);
      closeSnackbar(snackbarKey);
    } catch {
      emitSnackbar("Error downloading the export. Please contact the support team.", "error");
    }
  };

  const ToolTipContent = () => (
    <Box>
      <T fontSize={14}>Download CSV</T>
      Export size is currently limited to 6MB or 5000 records, which ever is greater. You will receive an error if your
      export exceeds this limit.
    </Box>
  );

  return (
    <Tooltip title={<ToolTipContent />}>
      <MenuItem onClick={triggerDownload}>
        <ListItemIcon>
          <Download />
        </ListItemIcon>
        <ListItemText>Download CSV</ListItemText>
      </MenuItem>
    </Tooltip>
  );
};

export type MenuProps = {
  handleOpenColumnSelectionModal: () => void;
};

export default function RelevantTextRecordsMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [, setColumnVisibilityModel] = useAtom(GridColumnVisibility);

  const [, setModalOpen] = useAtom(ColumnSelectModalOpen);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenColumnModal = () => {
    handleClose();
    setModalOpen(true);
  };

  return (
    <div>
      <StyledIconButton
        sx={{ bgcolor: "white" }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </StyledIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleOpenColumnModal}>
          <ListItemIcon>
            <ViewColumn />
          </ListItemIcon>
          <ListItemText>Select columns</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => setColumnVisibilityModel({})}>
          <ListItemIcon>
            <VisibilityOutlined />
          </ListItemIcon>
          <ListItemText>Show all columns</ListItemText>
        </MenuItem>

        <DownloadMenuItem />
      </Menu>
    </div>
  );
}
