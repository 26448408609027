import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";

import useMarketResearchStore from "../useMarketResearchStore";
import StudyTile from "./StudyTile";

export default function AllStudies() {
  const { isLoadingStudies, studies, studyId, resetState } = useMarketResearchStore();

  // Reset state when landing on the All Studies Page
  useEffect(() => {
    if (studyId) {
      resetState();
    }
  }, []);

  return (
    <Box sx={{ marginTop: `${TOP_BAR_HEIGHT}px`, paddingX: "10rem", paddingY: "2rem", width: "100%" }}>
      <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
        All Studies
      </Typography>
      {isLoadingStudies ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2, 1200: 3, 2000: 3 }}>
          <Masonry gutter="15px">
            {(studies ?? []).map((study, i) => {
              return <StudyTile study={study} key={study.study_key} />;
            })}
          </Masonry>
        </ResponsiveMasonry>
      )}
    </Box>
  );
}
