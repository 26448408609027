import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAuthorization } from "../../api/auth";
import { ErrorPage, LoadingPage, RoleSelectorPage } from "../../pages";

import useUserNavigation from "../../useUserNavigation";
import Roles from "./Roles";
import useAuthorizationManager from "./useAuthorizationManager";

const authUrl = import.meta.env.VITE_APP_AUTH_URL;
const redirectUrl = import.meta.env.VITE_APP_REDIRECT_URL;

export const JwtRoles = {
  COMM_ROLE: "deepsense_commercial_nlp_users",
  MED_ROLE: "deepsense_medical_nlp_users",
  MED_INFO_ROLE: "deepsense_med_info",
  USLT_RETRO_ROLE: "deepsense_uslt_retro",
  CMG_HEALTHCARE_LAW: "deepsense_cmg_healthcare_law",
  IT_CMG: "deepsense_it_cmg",
  PMR_ROLE: "deepsense_pmr",

  // TODO: Verify if these are still in use
  C360_ROLE: "ssfcmgoasis_c360_users",
  C360_ROLE_MGMT: "ssfrolemgmt_ecosys_c360",
};

const parseUserRoles = (authorizationRoles: string[]) => {
  // Convert roles to lowercase and strip the environment specific portion of the string from the role
  const lowerRoles = (authorizationRoles ?? []).map((r) => r.toLowerCase().replace(/(_dev_|_qa_|_prod_)/i, "_"));

  // users should have medical OR comm role; throw error if role not assigned
  if (!lowerRoles || Object.values(JwtRoles).every((r) => !lowerRoles.includes(r))) {
    throw "You are not assigned a role.";
  }

  // assign frontend roles based on authorization response
  let roles: string[] = [];

  if (lowerRoles.includes(JwtRoles.MED_ROLE)) {
    roles.push(Roles.MEDICAL);
  }
  if (lowerRoles.includes(JwtRoles.MED_INFO_ROLE)) {
    roles.push(Roles.MED_INFO);
  }
  if (lowerRoles.includes(JwtRoles.COMM_ROLE)) {
    roles.push(Roles.COMMERCIAL);
  }
  if (lowerRoles.includes(JwtRoles.USLT_RETRO_ROLE)) {
    roles.push(Roles.USLT_RETRO);
  }
  // if (lowerRoles.includes(JwtRoles.PMR_ROLE)) {
  roles.push(Roles.PRIMARY_MARKET_RESEARCH);
  // }
  if (lowerRoles.includes(JwtRoles.C360_ROLE) || lowerRoles.includes(JwtRoles.C360_ROLE_MGMT)) {
    roles.push(Roles.C360);
  }

  // NOTE: LAW and CMG need access to the MED_INFO charts feature but will not be placed in the AD role
  // ref: https://jira.gene.com/jira/browse/LEIBNIZ-1902
  if (lowerRoles.includes(JwtRoles.CMG_HEALTHCARE_LAW)) {
    roles.push(Roles.CMG_HEALTHCARE_LAW);
    roles.push(Roles.MED_INFO);
  }

  if (lowerRoles.includes(JwtRoles.IT_CMG)) {
    roles.push(Roles.IT_CMG);
    roles.push(Roles.MED_INFO);
  }

  roles = Array.from(new Set(roles));
  return roles;
};

const hasMultipleDomains = (roles: string[]) =>
  roles.filter((r) => [Roles.MEDICAL, Roles.COMMERCIAL, Roles.USLT_RETRO].includes(r)).length > 1;

const Authorization = () => {
  const { navigateToHomepage } = useUserNavigation();
  const AuthorizationManager = useAuthorizationManager();

  const [searchParams] = useSearchParams();
  const nav = useNavigate();

  const [authError, setAuthError] = useState<string | undefined>();

  const accessCode = searchParams.get("code");

  // Handle login flow when /auth?code= is present
  useEffect(() => {
    if (accessCode && !AuthorizationManager.AuthorizationResponse) {
      // Retrieve Auth Response
      getAuthorization(authUrl, redirectUrl, accessCode)
        .then((response) => {
          const { jwt, role, message } = response;

          if (!jwt || !role) {
            throw message || undefined;
          }

          AuthorizationManager.setAuthorizationResponse(response);
        })
        .catch((err: string) => {
          setAuthError(err);
        });
    }
  }, [accessCode]);

  // When we recieve an AuthorizationResponse, parse and retain the initial state
  useEffect(() => {
    const { AuthorizationResponse, UserState } = AuthorizationManager;

    const authIsSet = !!AuthorizationResponse;
    const jwt = AuthorizationResponse?.jwt;

    if (authIsSet && jwt && UserState.assignedRoles.length == 0) {
      const roles = parseUserRoles(AuthorizationResponse.role ?? []);
      const userHasMultipleDomains = hasMultipleDomains(roles);

      // Writing to this variable will trigger the useEffect([AuthorizationManager.UserState]) below
      AuthorizationManager.setUserState((_) => ({
        ..._,
        token: jwt,
        assignedRoles: roles,
        hasMultipleDomains: userHasMultipleDomains,

        // If a user has multiple domains leave the SelectedRoles empty so that we know to show the user the Role Selection page
        selectedRoles: userHasMultipleDomains ? [] : roles,
      }));
    }
  }, [AuthorizationManager.AuthorizationResponse]);

  // Handle updates to AuthorizationManager.UserState
  useEffect(() => {
    const { UserState } = AuthorizationManager;

    const userHasAppliedRoles = UserState.selectedRoles.length > 0;

    // Once we have AppliedRoles mark the logging in [isLoading] sequence as complete
    if (userHasAppliedRoles && UserState.token) {
      AuthorizationManager.setUserState((_) => ({
        ..._,
        isLoading: false,
      }));

      // Auth flow *should* be complete, redirect the user to their home page
      navigateToHomepage();
    }
  }, [AuthorizationManager.UserState]);

  // if we want react-router-dom to handle errors for us, we have to put this outside
  // of the try-catch.
  if (authError) {
    return (
      <>
        <ErrorPage authErrorMessage={authError} />
      </>
    );
  }

  if (AuthorizationManager.UserState.hasMultipleDomains && AuthorizationManager.UserState.selectedRoles.length == 0) {
    return (
      <RoleSelectorPage
        setUsersDomainRole={(domainRole: string) => {
          AuthorizationManager.setSelectedDomain(domainRole);
        }}
        userRoles={AuthorizationManager.UserState.assignedRoles}
      />
    );
  }

  return <LoadingPage />;
};

export default Authorization;
