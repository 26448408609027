import { ChatOutlined } from "@mui/icons-material";
import { Badge, Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useDeepChatSocketContext } from "../../../contexts/DeepChatSocketContext";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";
import useMarketResearchStore from "../useMarketResearchStore";
import InterviewComponent from "./InterviewComponent";
import QuestionInputBox from "./QuestionInputBox";

export default function ViewStudy() {
  const params = useParams();
  const navigate = useNavigate();
  const DeepChatSocket = useDeepChatSocketContext();

  const {
    studyQuestions,
    isLoadingQuestions,
    question,
    setQuestion,
    askQuestion,
    clearChatSession,
    studyId,
    setStudyId,
    getStudy,
    toggleFilterDrawer,
  } = useMarketResearchStore();

  // On Render check if studyId is synced
  useEffect(() => {
    if (params.studyId && params.studyId !== studyId) {
      setStudyId(params.studyId);
    }
  }, [params]);

  // Try to get study details by its "study_key"
  const study = studyId ? getStudy(studyId) : undefined;

  const askDeepchatQuestion = (question: string) => {
    if (study?.study_title) {
      clearChatSession();
      askQuestion(DeepChatSocket.sendJsonMessage, question, study.study_title);
      navigate(`/market-research/threads/`);
    }
  };

  // Example Question: What feedback is being given about Actemra?
  // Example Question: How many moderate to severe RA patients do specialists manage in a typical month? How many of those patients are on advanced biologics such as Humira or Enbrel?
  return (
    <Box
      component="main"
      sx={{
        p: 0,
        paddingLeft: "2em",
        paddingTop: `${TOP_BAR_HEIGHT}px`,
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Box sx={{ padding: "24px", width: "75%", height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: "0 0 auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            flexDirection={"row"}
          >
            <Typography variant="h5" sx={{ color: "#004677" }}>
              {study?.study_title}
            </Typography>
            <Link to={"/market-research"} style={{ textDecoration: "none" }}>
              CHANGE STUDY
            </Link>
          </Box>
        </Box>
        <Box sx={{ my: "2em" }}>
          <QuestionInputBox
            loading={false}
            onQuestionInput={(text: string) => {
              setQuestion(text);
            }}
            onFilterClick={() => toggleFilterDrawer()}
            onSubmit={() => askDeepchatQuestion(question)}
            question={question}
            placeholder="Ask Deepsense..."
          />
        </Box>
        {isLoadingQuestions ? (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: "1rem",
              paddingRight: "10px",
              flex: "1 1 0px", // Allow the box to grow and shrink within available space
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              minHeight: "300px",
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
              Not sure what to ask?
            </Typography>
            <Box>
              {(studyQuestions ?? []).length > 0 ? (
                <Grid container spacing={2}>
                  {(studyQuestions ?? []).map((question, i) => {
                    return (
                      <Grid item xs={6} key={i}>
                        <Box
                          flexDirection={"row"}
                          gap={3}
                          onClick={() => {
                            // Davi sez turn off for now - Eric
                            // const questionText = "Summarize the discussion question: " + question.question_title;
                            // setQuestion(questionText);
                            // askDeepchatQuestion(questionText);
                          }}
                          sx={{
                            background: "#EFF0F0",
                            padding: "20px",
                            borderRadius: "10px",
                            display: "flex",
                            // cursor: "pointer",
                          }}
                        >
                          <Badge
                            badgeContent={question.responses}
                            color="secondary"
                            sx={{
                              "& .MuiBadge-badge": {
                                color: "white",
                              },
                            }}
                          >
                            <ChatOutlined fontSize="large" sx={{ color: "#003087", marginTop: "5px" }} />
                          </Badge>
                          <Typography variant="h6" sx={{ fontSize: "14px" }}>
                            {question.question_title}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: "20px", color: "#13294B" }}>
                    No Questions Available
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          ml: "2em",
          flex: 1,
          display: "flex",
          borderLeft: "1px solid #eee",
          overflow: "hidden",
          flexDirection: "column",
          padding: "2rem",
        }}
      >
        <InterviewComponent {...study} />
      </Box>
    </Box>
  );
}
