import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { getRecentDocumentsHistory } from "../../api/dataSources";
import useUserContext from "../../components/Authorization/useUserContext";
import { TRecentDocumentsHistory } from "../../components/Deepchat/models";

export const loadingAtom = atom(true);
export const docHistoryAtom = atom<TRecentDocumentsHistory[]>([]);

export const useRecentDocumentStore = () => {
  const user = useUserContext();
  const [loading, setLoading] = useAtom(loadingAtom);
  const [docHistory, setDocHistory] = useAtom(docHistoryAtom);

  const callRecentDocumentsHistory = async () => {
    try {
      const response = await getRecentDocumentsHistory(user);
      setDocHistory(response);
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    callRecentDocumentsHistory();
  }, []);

  return {
    loading,
    docHistory,
  };
};
