import { AutoAwesomeOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  LabelDisplayedRowsArgs,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Typography as T,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import ChartCard from "../../../components/ChartCard";
import VerbatimsGrid from "../../../components/charts/VerbatimsGrid";
import ViewToggle, { VIEW_CARDS, VIEW_GRID } from "../../../components/ViewToggle";
import { GetTableColumnsFromSearchResult } from "../../../constants/verbatimTableColumns";
import { useFilterContext } from "../../../contexts/FilterProvider";
import useUserFeedback from "../../../contexts/useUserFeedback";
import { useVerbatims } from "../../../contexts/VerbatimsProvider";
import FeatureFlag from "../../../featureFlags/FeatureFlag";
import { Feature } from "../../../featureFlags/featureFlagConfig";
import { SearchResults, Sort, SortDirEnum } from "../../../models/search";
import { compactFormatter } from "../../../utils/numberFormatter";
import { toggleStackedRelated } from "../../filters/FilterFunctions";
import VerbatimFeedbackModal from "../VerbatimFeedbackModal";
import { FeedbackItems } from "../VerbatimsCard/FeedbackState";
import VerbatimsCards from "../VerbatimsCard/VerbatimsCards";
import ColumnSelectModal from "./ColumnSelectModal";
import ExecutiveSummary from "./ExecutiveSummary";
import RelevantTextRecordsMenu from "./RelevantTextRecordsMenu";
import {
  ExecSummaryDisableButtonAtom,
  ExecSummaryEnableTooltipAtom,
  ExecSummaryHasContentAtom,
  TriggerExecSummaryAtom,
} from "./state/ExecutiveSummaryState";
import { VerbatimColumns } from "./state/VerbatimColumns";
import useUserContext from "../../Authorization/useUserContext";

const RelevantRecordsContent = () => (
  <Paper sx={{ p: 2, color: "#13294B" }}>
    <T fontWeight={900}>Text Summarization</T>
    Abstractive text summaries are created using generative AI. If a document is sufficiently long (approximately 100
    words), a small model attempts to create a short summary (approximately 5-50 words) using only the verbatim text. If
    a document is too short, no summary is shown.
  </Paper>
);

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

type SortOptionType = {
  label: string;
  value: Sort;
};

const SORT_BY_OPTIONS: SortOptionType[] = [
  { label: "Date (Asc)", value: { leibniz_filter_date: SortDirEnum.asc } },
  { label: "Date (Desc)", value: { leibniz_filter_date: SortDirEnum.desc } },
  { label: "Source (Asc)", value: { data_source: SortDirEnum.asc } },
  { label: "Source (Desc)", value: { data_source: SortDirEnum.desc } },
  { label: "Sentiment (Asc)", value: { sentiment: SortDirEnum.asc } },
  { label: "Sentiment (Desc)", value: { sentiment: SortDirEnum.desc } },
];

const getSortValueForDisplay = (sort: string): string => {
  const sortOption = SORT_BY_OPTIONS.find(({ value }) => JSON.stringify(value) === sort);
  return sortOption?.value ? JSON.stringify(sortOption?.value) : "";
};

export default function RelevantTextRecordsCard() {
  const user = useUserContext();
  const userRoles = user.selectedRoles;

  const { applyFilters, filterState } = useFilterContext();
  const { records, numRecords, updateSort, sort, pageSize, setPageSize, start, setStart, isLoading } = useVerbatims();
  const [view, setView] = useState<string>(VIEW_CARDS);
  const cardRef = useRef<HTMLDivElement>(null);

  const [, setTriggerExecSummary] = useAtom(TriggerExecSummaryAtom);
  const [execSummaryEnableTooltip] = useAtom(ExecSummaryEnableTooltipAtom);
  const [execSummaryDisableButton] = useAtom(ExecSummaryDisableButtonAtom);
  const [execSummaryHasContent] = useAtom(ExecSummaryHasContentAtom);

  const { getAllUserFeedback } = useUserFeedback();
  const [, setUserFeedback] = useAtom(FeedbackItems);

  const { data: userFeedbackData } = getAllUserFeedback();
  useEffect(() => {
    setUserFeedback(userFeedbackData || []);
  }, [userFeedbackData]);

  // With the Grid and CSV Export dependant on multiple cross cutting variables, we are now using Jotai to manage state
  const [, setColumns] = useAtom(VerbatimColumns);
  useEffect(() => {
    const isStacked = filterState?.stack_related ?? false;
    setColumns(GetTableColumnsFromSearchResult(records, isStacked));
  }, [records, filterState]);

  const sortValue = getSortValueForDisplay(JSON.stringify(sort));

  const scrollToTop = () => {
    if (cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onViewChange = (_event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView) {
      setView(newView);
      // reset sort on view change
      if (sort) {
        const currentSortKey = Object.keys(sort)[0] as keyof SearchResults;
        updateSort(currentSortKey, null);
      }
    }
  };

  const onSortChange = (e: SelectChangeEvent): void => {
    const { value } = e.target;
    const newSort: Sort = JSON.parse(value);
    const newSortKey = Object.keys(newSort)[0] as keyof SearchResults;
    const newSortDir = Object.values(newSort)[0];
    updateSort(newSortKey, newSortDir);
  };

  const onPageChange = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    // start is based on the item index for the first item in the next page,
    // e.g. page 2 for a page size of 100 has a start of 100 (page 1 is 0-99).
    setStart(page * pageSize);
    // Auto scroll back to the top of the card list
    scrollToTop();
  };
  // Reset back to page 1 when the user toggles between views
  useEffect(() => {
    setStart(0);
  }, [view]);

  const onPageSizeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(Number(e.target.value));
    scrollToTop();
  };

  const toggleStacked = () => {
    if (filterState) {
      applyFilters(toggleStackedRelated(filterState));
    }
  };

  const showDuplicates = !filterState?.stack_related;

  return (
    <ChartCard
      title={["Relevant Text Records", ...(isLoading ? [] : [`${compactFormatter.format(numRecords ?? 0)} records`])]}
      tooltipContent={<RelevantRecordsContent />}
      backgroundColor={"white"}
      sx={{ padding: "18px 18px 0 18px" }}
      actions={
        <Box display="flex" flexDirection="row" ref={cardRef} gap="0.625rem">
          <Box display="grid" sx={{ placeItems: "center" }}>
            <Tooltip
              slotProps={{
                tooltip: {
                  sx: {
                    color: "#25282A",
                    fontSize: "14px",
                    maxWidth: "16rem",
                    lineHeight: "1rem",
                    padding: "0.625rem",
                    backgroundColor: "white",
                    filter:
                      "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.2)) drop-shadow(0px 1px 3px rgba(50, 50, 71, 0.1))",
                  },
                },
                arrow: {
                  sx: { color: "white" },
                },
              }}
              placement="top"
              title={
                execSummaryEnableTooltip
                  ? "Perform a filter or search that returns between 1 and 10,000 records to enable AI summarization"
                  : ""
              }
              arrow
            >
              {/* `span` allows tooltip to appear on disabled button */}
              <span style={{ gridArea: "1/1" }}>
                <Button
                  onClick={() => {
                    setTriggerExecSummary((i) => ++i);
                  }}
                  disabled={execSummaryDisableButton}
                  sx={{
                    minWidth: "max-content",
                    px: "1rem",
                    color: "#003087",
                    visibility: execSummaryHasContent ? "hidden" : undefined,
                  }}
                  startIcon={<AutoAwesomeOutlined />}
                >
                  Generate Summary
                </Button>
              </span>
            </Tooltip>
          </Box>
          <FeatureFlag flag={Feature.ParentChild}>
            {!userRoles.includes("uslt_retro") && (
              <Box
                sx={{ display: "flex", flexDirection: "row", textWrap: "nowrap" }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Tooltip
                  slotProps={{
                    tooltip: {
                      sx: {
                        color: "#25282A",
                        fontSize: "14px",
                        maxWidth: "16rem",
                        lineHeight: "1rem",
                        padding: "0.625rem",
                        backgroundColor: "white",
                        filter:
                          "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.2)) drop-shadow(0px 1px 3px rgba(50, 50, 71, 0.1))",
                      },
                    },
                    arrow: {
                      sx: { color: "white" },
                    },
                  }}
                  placement="top"
                  title={
                    "Show all records with similar descriptions when enabled (grouped icon and count will not be shown)"
                  }
                  arrow
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch size="small" checked={showDuplicates} onChange={toggleStacked} />}
                      label={
                        <T fontWeight={"600̦"} color="textSecondary">
                          Show Duplicates
                        </T>
                      }
                      aria-label="Show Duplicates"
                    />
                  </FormGroup>
                </Tooltip>
              </Box>
            )}
          </FeatureFlag>
          {view === VIEW_CARDS && (
            <FormControl size="small" fullWidth>
              {!sort && (
                <InputLabel id="sortby-dropdown-label" shrink={false}>
                  Sort by
                </InputLabel>
              )}
              <Select
                labelId="sortby-dropdown-label"
                id="sortby-dropdown"
                label="SortBy"
                notched={false}
                value={sortValue}
                onChange={onSortChange}
                sx={{ minWidth: "105px", size: "14px", bgcolor: "white" }}
              >
                {SORT_BY_OPTIONS.map(({ value, label }) => (
                  <MenuItem key={label} value={JSON.stringify(value)}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box>
            <ViewToggle selectedView={view} handleViewChange={onViewChange} />
          </Box>
          <RelevantTextRecordsMenu />
        </Box>
      }
    >
      <Box display="flex" flexDirection="column">
        <ExecutiveSummary />
        <Box sx={{ height: "calc(100vh - 167px)", overflowY: "scroll", padding: "2px" }}>
          {view === VIEW_CARDS ? <VerbatimsCards sort={sort} /> : <VerbatimsGrid />}
          {numRecords && !isLoading && view === VIEW_GRID ? (
            <TablePagination
              component="div"
              count={numRecords}
              page={Math.floor(start / pageSize)}
              showFirstButton
              onPageChange={onPageChange}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[25, 50, 100]}
              onRowsPerPageChange={onPageSizeChange}
              labelDisplayedRows={({ from, to, count }: LabelDisplayedRowsArgs) =>
                `${from}—${to} of ${compactFormatter.format(count)} records`
              }
            />
          ) : null}
        </Box>
      </Box>
      <ColumnSelectModal />
      <VerbatimFeedbackModal />
    </ChartCard>
  );
}
