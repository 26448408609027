import { Box, Button, Input, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { getQuerySalesResponse } from "../../../api/deepchat";
import useDeepchat, { WebSocketChatResponse } from "../../../contexts/useDeepchat";
import useUserContext from "../../Authorization/useUserContext";
import DeepchatNavigationTabs from "../DeepchatNavigationTabs";
import { formatResponseText } from "../SessionThread/SessionResponseItem";

export default function QuerSalesData() {
  const user = useUserContext();

  const [input, setInput] = useState("");
  const [response, setRepsonse] = useState<WebSocketChatResponse<unknown>[]>([]);

  const submitSearch = async (question: string) => {
    console.log("submitSearch:response", response);
  };

  // What is the current trend with Xolair?
  return (
    <>
      <Box flex={1} minHeight="100%" marginX="auto" maxWidth="48rem">
        <Stack
          className="QuestionInput"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ minHeight: "100%" }}
        >
          <Typography variant="h3" fontWeight="bold" color={"rgb(0, 48, 135)"}>
            Deepchat
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <DeepchatNavigationTabs />
            {/* <QuestionInput placeholder="Ask a related follow-up question…" hideEnhanceButton={true} /> */}
            <Input
              value={input}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInput(event.target.value);
              }}
            />
            <Button onClick={() => submitSearch(input)}>Go</Button>
          </Box>
          <Box>
            {response.map((_, i) => {
              if (_.type == "chat.answer") {
                const content = _.data as { answer: string };
                return <Box key={i}>{formatResponseText(content.answer)}</Box>;
              }
            })}
          </Box>
        </Stack>
      </Box>
    </>
  );
}
