import { useAtom } from "jotai";
import { WebSocketChatResponse } from "../../contexts/useDeepchat";
import emitSnackbar from "../../emitSnackbar";
import { ChatResponseAnswerPartial } from "../Deepchat/models";
import { ChatInteraction, MarketResearchStateAtom, PmrDataSource } from "./models";

export default function useMarketResearchMessageHandler() {
  const [state, setState] = useAtom(MarketResearchStateAtom);

  const processSocketMessage = (lastJsonMessage: WebSocketChatResponse<unknown>) => {
    switch (lastJsonMessage?.type) {
      case "chat.initial": {
        const message = lastJsonMessage as WebSocketChatResponse<ChatInteraction>;

        // Handle adding responses to the session thread state object
        setState((_state) => {
          const state = { ..._state };

          const chatInteraction = { ...message.data, complete: false } as ChatInteraction;
          state.messages = [..._state.messages, chatInteraction];

          return state;
        });

        break;
      }

      case "chat.answer": {
        const message = lastJsonMessage as WebSocketChatResponse<ChatResponseAnswerPartial>;

        setState((_state) => {
          // Do not create a new array instance since we are not adding a new item to it, just updating the contents
          const messages = _state.messages;
          const lastInteraction = messages.at(-1);
          if (lastInteraction && message) {
            lastInteraction.answer = message.data.answer;
          }
          return _state;
        });
        break;
      }

      case "chat.source": {
        const message = lastJsonMessage as WebSocketChatResponse<PmrDataSource>;
        setState((_state) => {
          const lastInteraction = _state.messages.at(-1);
          if (lastInteraction && message) {
            lastInteraction.source_ids = [...(lastInteraction.source_ids ?? []), message.data.record_id];
          }
          return _state;
        });
        break;
      }

      case "chat.complete": {
        setState((_state) => {
          // Mark the last interaction as complete
          const lastInteraction = _state.messages.pop();
          if (lastInteraction) {
            // In order to know when to trigger a query for source data documents we are using a `complete` property at the interaction level
            _state.messages = [..._state.messages, { ...lastInteraction, complete: true }];
          }

          // Return the global state with an updated isLoading flag
          return { ..._state, isLoading: false };
        });

        // TODO: remove when we feel like we don't want this for debugging anymore
        console.log(state);
        break;
      }

      default: {
        if ((lastJsonMessage as any)?.message === "Internal server error") {
          emitSnackbar("Something went wrong, please try again in a moment", "warning");
        }
      }
    }
  };

  return {
    processSocketMessage,
  };
}
