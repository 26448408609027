import { Chat, Edit, AutoAwesomeOutlined } from "@mui/icons-material";
import { Box, Typography, Card, Link } from "@mui/material";
import { ChatInteraction, FocusedInteraction } from "../models";
import { scrollQuestionIntoView } from "./StudyChat";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
//import SourceDocuments from "./SourceDocuments";

interface ChatAnswerNodeProps {
  interaction: ChatInteraction;
  anchorId: string;
}

export default function ChatAnswerNode({ interaction, anchorId }: ChatAnswerNodeProps) {
  const [focusedInteraction, setFocusedInteraction] = useAtom(FocusedInteraction);

  const { ref, inView } = useInView({ threshold: 0.8 });

  useEffect(() => {
    if (inView && focusedInteraction !== interaction) {
      // Setting this value updates which Question is highlighted in <ChatSessionOutline />
      setFocusedInteraction(interaction);
    }
  }, [inView]);

  return (
    <Box>
      <Box sx={{ display: "flex", marginTop: "2rem", flex: 1 }} justifyContent={"space-between"}>
        <Link
          href={`${location.pathname}#${anchorId}`}
          id={anchorId}
          underline="none"
          onClick={(event) => {
            event.preventDefault();
            scrollQuestionIntoView(anchorId);
          }}
        >
          <Box sx={{ display: "flex" }} alignItems={"center"}>
            <Chat />
            <Typography variant="h5" sx={{ marginLeft: "0.5rem" }}>
              Original Question
            </Typography>
          </Box>
        </Link>
        <Box sx={{ display: "flex" }} alignItems={"center"}>
          <Edit />
        </Box>
      </Box>
      <Card sx={{ marginTop: "1rem" }}>
        <Typography sx={{ padding: "1rem" }}>{interaction.user_query}</Typography>
      </Card>
      <Box sx={{ alignItems: "center", mt: "2rem" }} ref={ref}>
        <Box>
          <AutoAwesomeOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
          <Typography variant="h6" color={"#13294B"} display="inline-block">
            Deepchat Answer
          </Typography>
          <Card sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Box sx={{ padding: "1rem" }}>{formatResponseText(interaction.answer ?? "")}</Box>
          </Card>
        </Box>
      </Box>

      {/* <Box>{interaction.complete && <SourceDocuments study={study} source_ids={interaction.source_ids} />}</Box> */}
    </Box>
  );
}

const formatResponseText = (text: string) => {
  const response = (text || "").split("\n").flatMap((segment, i) => {
    const isListItem = segment.startsWith("- ");
    const isBlankLine = !segment;

    if (isListItem) {
      // Clean off the "- " and make element a <li>
      return <li key={i}>{segment.replace(/^(- )/, "")}</li>;
    }

    if (segment.endsWith(":")) {
      return (
        <Typography sx={{ fontWeight: "bold" }} key={i}>
          {segment}
        </Typography>
      );
    }

    return (
      <Typography py={isBlankLine ? "0.5em" : 0} key={i}>
        {segment}
      </Typography>
    );
  });

  return response;
};
