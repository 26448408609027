import { AccessTime, CheckCircle, Error, History } from "@mui/icons-material";
import { Box, PaperProps, Skeleton, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useRecentDocumentStore } from "../../../store/home/useRecentDocumentStore";

const recentDocuments = [
  {
    filename: "Document1.pdf",
    status: "emailed",
    upload_timestamp: new Date(),
  },
  {
    filename: "Document2.pdf",
    status: "processing",
    upload_timestamp: new Date(),
  },
  {
    filename: "Document3.pdf",
    status: "processing",
    upload_timestamp: new Date(),
  },
];

export default function RecentDocuments({ sx, ...props }: PaperProps) {
  const { docHistory, loading } = useRecentDocumentStore();

  const showSkeleton = () => {
    return [...Array(4).keys()].map((i) => (
      <Skeleton key={i} variant="rounded" height={42} sx={{ marginTop: "1em" }} />
    ));
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "emailed":
        return "green";
      case "processing":
        return "#FFA500";
      case "failed":
        return "#red";
      default:
        return "blue";
    }
  };

  const ShowDetailsTextView = ({ status, date, showIcon }: { status: string; date?: string; showIcon?: boolean }) => {
    const ICON =
      status === "emailed" ? (
        <CheckCircle sx={{ fill: getStatusColor(status), width: "0.6em", height: "0.6em" }} />
      ) : status === "failed" ? (
        <Error sx={{ fill: getStatusColor(status), width: "0.6em", height: "0.6em" }} />
      ) : (
        <AccessTime sx={{ fill: getStatusColor(status), width: "0.6em", height: "0.6em" }} />
      );
    return (
      <Box display="flex" flexDirection={"row"} alignItems={"center"}>
        {showIcon ? ICON : null}
        <Typography
          style={{
            fontSize: date ? "0.8em" : "0.8em",
            color: getStatusColor(status),
            textTransform: "capitalize",
          }}
        >
          {date ? dayjs(date).format("M/D/YYYY h:m A") : status}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...sx,
        ...{
          "& .document-list": {
            marginBottom: "1em",

            "& .title": {
              fontWeight: "bold",
              fontSize: "1.2em",
            },
            "& .details": {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            },
          },
        },
      }}
    >
      <Box>
        <History sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
        <Typography variant="h6" color={"#13294B"} display="inline-block">
          Recent Documents
        </Typography>
      </Box>
      <Box sx={{ mt: "1em" }}>
        {loading
          ? showSkeleton()
          : docHistory.map((document, i) => {
              return (
                <Box className="document-list" key={i}>
                  <Typography className="title">{document.original_document_name}</Typography>
                  <Box className="details">
                    <ShowDetailsTextView status={document.document_status} showIcon={true} />
                    <ShowDetailsTextView
                      status={document.document_status}
                      date={document.create_datetime}
                      showIcon={false}
                    />
                  </Box>
                </Box>
              );
            })}
      </Box>
    </Box>
  );
}
