import { QuestionAnswerOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { AutoSizer } from "react-virtualized";
import { SalesDataStateAtom } from "./SalesDataState";
import { useAtom } from "jotai";
import { WebSocketChatResponse } from "../../contexts/useDeepchat";
import { DataGrid } from "@mui/x-data-grid";
import ResponseNode from "./ResponseNode";
import { CodeResponse, MessageResponse, TabularResponse, UserQuestion } from "./SalesDataTypes";

const MockOutline = () => (
  <>
    <Box>
      <QuestionAnswerOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
      <Typography variant="h6" color={"#13294B"} display="inline-block">
        Current Thread
      </Typography>
    </Box>

    <Box
      sx={{
        overflowY: "scroll",
        pr: "2em",
        flex: 1,
        minHeight: 0,
        "& .thread-question": {
          marginBottom: "1em",
          color: "blue",
          cursor: "pointer",

          "&.focused": { borderLeft: "2px solid navy", color: "navy", pl: "0.5em" },
        },
      }}
    >
      Example Item
    </Box>
  </>
);

export default function SalesDataSession() {
  const [state] = useAtom(SalesDataStateAtom);

  return (
    <>
      {/* Main body content */}
      <Box flex={1} minHeight="100%" maxWidth="48rem" marginX="auto">
        <Box sx={{ "& pre": { whiteSpace: "pre-wrap" } }}>
          {/* I'm not sure I like switching out the content directly from the message stack, will revisit */}
          {state.messages.map((rawMessage, i) => {
            if (rawMessage.type == "chat.initial") {
              const message = rawMessage as WebSocketChatResponse<UserQuestion>;

              return (
                <ResponseNode key={i} type={"user_question"} header={<span>Original Question</span>}>
                  {message.data.user_question}
                </ResponseNode>
              );
            }

            if (rawMessage.type == "chat.structured_data") {
              const baseMessage = rawMessage as WebSocketChatResponse<{ type: string }>;

              if (baseMessage.data.type == "code") {
                const message = rawMessage as WebSocketChatResponse<CodeResponse>;

                return (
                  <ResponseNode
                    key={i}
                    type={message.data.type}
                    language={message.data.language}
                    header={<span>Deepchat Answer - {message.data.type}</span>}
                  >
                    {message.data.code}
                  </ResponseNode>
                );
              }

              if (baseMessage.data.type == "message") {
                const message = rawMessage as WebSocketChatResponse<MessageResponse>;

                return (
                  <ResponseNode
                    key={i}
                    type={baseMessage.data.type}
                    header={<span>Deepchat Answer - {message.data.type}</span>}
                  >
                    <Box sx={{ whiteSpace: "pre-wrap" }}>{message.data.message}</Box>
                  </ResponseNode>
                );
              }

              if (baseMessage.data.type == "tabular") {
                const message = rawMessage as WebSocketChatResponse<TabularResponse>;

                const columns = Object.keys(JSON.parse(message.data.columns));
                const rows = JSON.parse(message.data.data);

                return (
                  <ResponseNode
                    key={i}
                    type={baseMessage.data.type}
                    header={<span>Deepchat Answer - {message.data.type}</span>}
                  >
                    <Box sx={{ height: "420px", width: "100%" }}>
                      <DataGrid
                        columns={columns.map((_) => ({
                          field: _,
                          headerName: _,
                          with: 180,
                        }))}
                        rows={rows.map((_, i) => ({ ..._, id: i }))}
                        disableSelectionOnClick
                      />
                    </Box>
                  </ResponseNode>
                );
              }
            }

            return undefined;
          })}
        </Box>

        {/* Followup question container */}
        <Box sx={{ height: "84px" }} className="followup-input-base">
          <AutoSizer disableHeight>
            {({ width }) => (
              <Box
                sx={{
                  position: "fixed",
                  width: width,
                  bottom: 0,
                  backgroundImage: "linear-gradient(to bottom, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 25%)",
                  py: "2em",
                }}
              >
                {/* QuestionInput */}
              </Box>
            )}
          </AutoSizer>
        </Box>
      </Box>

      {/* Current Thread Fixed Sidebar  */}
      <>
        <Box sx={{ width: "24vw", minWidth: "360px" }}></Box>
        <Box
          sx={{
            width: "24vw",
            minWidth: "360px",
            ml: "2em",
            pl: "2em",
            borderLeft: "1px solid #eee",
            position: "fixed",
            right: 0,
          }}
        >
          <Box sx={{ display: "flex", gap: "1em", flexDirection: "column", height: "calc(100vh - 74px - 2em)" }}>
            <MockOutline />
          </Box>
        </Box>
      </>
    </>
  );
}
