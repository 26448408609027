import { AutoAwesomeOutlined, ChatBubbleOutline } from "@mui/icons-material";
import { Box, Typography, Paper, BoxProps, IconProps } from "@mui/material";

// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import python from "react-syntax-highlighter/dist/esm/languages/prism/python";
import sql from "react-syntax-highlighter/dist/esm/languages/prism/sql";
SyntaxHighlighter.registerLanguage("javascript", js);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("sql", sql);

import { oneLight as codeStyle } from "react-syntax-highlighter/dist/esm/styles/prism";

export type ResponseNodeProps = {
  type: string;
  header: React.ReactNode;
  language?: string;
} & BoxProps;

type ResponseIconProps = { type: string } & IconProps;
const ResponseIcon = ({ type, className }: ResponseIconProps) => {
  switch (type) {
    case "user_question":
      return <ChatBubbleOutline className={className} />;

    case "message":
      return <ChatBubbleOutline className={className} />;

    default:
    case "code":
      return <AutoAwesomeOutlined className={className} />;
  }
};

type FormatContentProps = {
  type?: string;
  language?: string;
} & BoxProps;
const FormatContent = ({ type, language, children, ...props }: FormatContentProps) => {
  if (type == "code") {
    return (
      <SyntaxHighlighter language={language} style={codeStyle} wrapLines>
        {children}
      </SyntaxHighlighter>
    );
  }

  return children;
};

export default function ResponseNode({ type, header, language, children, sx, ...props }: ResponseNodeProps) {
  console.log("ResponseNode", { type, language });
  return (
    <Box
      sx={{
        ...sx,
        px: "1em",
        mb: "2em",
        "& .MuiSvgIcon-root.ResponseNodeIcon": {
          fill: "#13294B",
          verticalAlign: "sub",
          marginRight: "0.5em",
        },
      }}
    >
      <Box sx={{ mb: "1em" }}>
        <ResponseIcon type={type} className="ResponseNodeIcon" />
        <Typography variant="h6" color={"#13294B"} display="inline-block">
          {header}
        </Typography>
      </Box>

      <Paper sx={{ padding: "1em" }}>
        <FormatContent type={type} language={language}>
          {children}
        </FormatContent>
      </Paper>
    </Box>
  );
}
