import { EditCalendar } from "@mui/icons-material";
import { Box, Card, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MarketResearchStudy } from "../models";

interface StudyTileProps {
  study: MarketResearchStudy;
}

export default function StudyTile({ study }: StudyTileProps) {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const studyPath = `/market-research/study/${study.study_key}`;
  return (
    <Box>
      <Link
        to={studyPath}
        style={{ textDecoration: "none" }}
        onClick={(e) => {
          e.preventDefault();
          navigate(studyPath);
        }}
      >
        <Card
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          sx={{
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "20px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Typography variant="h5" sx={{ fontSize: "24px" }}>
                {study.study_title}
              </Typography>
              <Divider />
              <Box sx={{ display: "flex", gap: "0.8rem" }} alignItems={"center"}>
                <EditCalendar />
                <Box sx={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "0.2rem" }} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontSize: "10px", color: "#425466" }}>
                      Fielded
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "13px", color: "#004677" }}>
                      {study.study_date}
                    </Typography>
                  </Box>
                  <Box sx={{ padding: "0.3rem", backgroundColor: "#00A3E0", borderRadius: "10px" }}>
                    <Typography variant="h6" sx={{ fontSize: "14px", color: "white" }}>
                      {study.num_respondents} respondents
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {hovered && (
            <Box sx={{ width: "100%", height: "100px", backgroundColor: "#EFF0F0", display: "none" }}>
              {Array.from(Array(10).keys()).map((i) => {
                return (
                  <ul key={i}>
                    <li>
                      <Typography variant="h6" sx={{ fontSize: "14px" }}>
                        1
                      </Typography>
                    </li>
                  </ul>
                );
              })}
            </Box>
          )}
        </Card>
      </Link>
    </Box>
  );
}
