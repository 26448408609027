import { ArrowForward } from "@mui/icons-material";
import { Box, Button, InputBase, Typography } from "@mui/material";
import { UUID } from "crypto";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";
import useMarketResearchStore from "../useMarketResearchStore";
import ChatAnswerNode from "./ChatAnswerNode";
import ChatSessionOutline from "./ChatSessionOutline";
import { useDeepChatSocketContext } from "../../../contexts/DeepChatSocketContext";

const getThreadId = (value: string) => {
  return value ? (value as UUID) : undefined;
};

export default function StudyChat() {
  const location = useLocation();
  const navigate = useNavigate();
  const DeepChatSocket = useDeepChatSocketContext();
  const { messages, question, setQuestion, askQuestion, studyId, getStudy, clearChatSession } =
    useMarketResearchStore();

  const startNewThread = () => {
    clearChatSession();
    navigate(`/market-research/study/${studyId}`);
  };

  const handleAskQuestion = (question: string, study_key: string) => {
    if (question.length) {
      askQuestion(DeepChatSocket.sendJsonMessage, question, study_key);
      setQuestion("");
    }
  };

  // Sync the session key once we get it from the current chat interaction
  // This may be overkill for trying to inject the UUID into the URI on page transition to this view
  useEffect(() => {
    const message = messages.at(-1);
    const path = location.pathname.split("/");
    const threadId = getThreadId(path.at(-1) ?? "");

    if (message && threadId != message.user_chat_session) {
      navigate(`/market-research/threads/${message.user_chat_session}`, {
        replace: true,
        state: { prevent_load: true },
      });
    }
  }, [messages, location]);

  // Scroll the title of the most recent question up into view when the session[] updates
  useEffect(() => {
    const target = messages.at(-1);
    if (target) {
      const anchorId = `${target?.user_chat_session}-${messages.indexOf(target)}`;
      scrollQuestionIntoView(anchorId);
    }
  }, [messages]);

  // Try to get study details by its "study_key"
  const study = studyId ? getStudy(studyId) : undefined;

  if (!studyId || !study) return undefined;

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: `${TOP_BAR_HEIGHT}px`,
        padding: "24px",
        overflow: "hidden",
        display: "flex",
        background: "#F5F5F9",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "75%" }}>
        <Box sx={{ height: "100%", overflow: "auto" }} className="StudyChat-Scroll">
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              flexDirection={"row"}
            >
              <Typography variant="h5" sx={{ color: "#004677" }}>
                {study?.study_title}
              </Typography>
              <Link to={"/market-research"} style={{ textDecoration: "none" }}>
                CHANGE STUDY
              </Link>
            </Box>

            {messages.map((interaction, i) => {
              const anchorId = `${String(interaction.user_chat_session)}-${i}`;
              return <ChatAnswerNode key={i} anchorId={anchorId} interaction={interaction} />;
            })}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "10px",
            marginTop: "1rem",
            backgroundColor: "white",
          }}
          border={1}
          borderRadius={5}
          borderColor={"#E0E0E0"}
          display={"flex"}
        >
          <InputBase
            sx={{ width: "100%" }}
            multiline
            value={question}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                handleAskQuestion(question, studyId);
              }
            }}
            onChange={(event) => {
              setQuestion(event.target.value);
            }}
          />
          <Button
            disableElevation
            variant="contained"
            aria-label="search"
            disabled={question.length === 0}
            onClick={() => handleAskQuestion(question, studyId)}
            sx={{
              padding: "8px",
              margin: "4px",
              minWidth: "unset",
              backgroundColor: question.length === 0 ? "#E0E0E0" : "#023088",
              color: question.length === 0 ? "#E0E0E0" : "white",
            }}
          >
            <ArrowForward />
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          ml: "2em",
          pl: "2em",
          flex: 1,
          display: "flex",
          borderLeft: "1px solid #eee",
          overflow: "hidden",
        }}
      >
        <Box sx={{ display: "flex", gap: "1em", flexDirection: "column", height: "100%", overflow: "hidden" }}>
          <ChatSessionOutline messages={messages} onClickNewThread={() => startNewThread()} />
        </Box>
      </Box>
    </Box>
  );
}

export const scrollQuestionIntoView = (anchorId: string) => {
  const element = document.getElementById(anchorId);
  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY - (48 + 24); // Menu + 1em padding
    document.getElementsByClassName("StudyChat-Scroll")[0].scrollTo({ top: y, behavior: "smooth" });
  }
};
