import { ArrowForward, Delete, UploadFile } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, ButtonGroup, Input, Paper, Typography } from "@mui/material";
import { useRef } from "react";
import { grey } from "../../../colors";
import { useFileUploadPanelStore } from "../../../store/home/useFileUploadPanelStore";

import CardTooltip from "../../cards/CardToolTip";
import CommonTooltipContent from "../../CommonTooltipContent";
import { TCompressionLevel } from "../models";
import UploadSettingsModal from "./UploadSettingsModal";

export default function FileUploadPanel() {
  const { loading, file, setFile, setOpen, compressionArray, updateCompressionArray, callAPI } =
    useFileUploadPanelStore();
  const errors: string[] = [];
  const inputRef = useRef<HTMLInputElement>(null);

  if (file && file.size > 5 * 1000 * 1024) {
    errors.push("File selected is too large");
  }

  const bytesToMB = (bytes: number): string => {
    if (bytes === 0) return "0 Bytes"; // Handle zero-size files

    // Convert to MB if the size is greater than or equal to 1 MB
    if (bytes >= 1024 * 1024) {
      const megabytes = bytes / (1024 * 1024);
      return megabytes.toFixed(2) + " MB"; // Return in MB, rounded to 2 decimal places
    }

    // Convert to KB if the size is less than 1 MB
    const kilobytes = bytes / 1024;
    return kilobytes.toFixed(2) + " KB";
  };

  const CompressionLevelButton = ({ item }: { item: TCompressionLevel }) => {
    return (
      <Button
        variant={item.isSelected ? "contained" : "outlined"}
        style={{ marginBottom: "4px", color: "#416CD8", background: item.isSelected ? "#E1E7F7" : "#fff" }}
        onClick={() => updateCompressionArray(item)}
      >
        {item.value}
      </Button>
    );
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box sx={{ ml: 1, flex: 1, px: "0.5rem", mt: 1 }}>
        {file && (
          <Box display={"flex"} sx={{ marginBottom: "0.5rem" }} alignItems={"center"} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"}>
              <Button onClick={() => setFile(null)}>
                <Delete sx={{ color: "#D4D5D6", width: "1.2rem", height: "1.2rem" }} />
              </Button>
              <Typography sx={{ color: "#314362" }} fontWeight={"600"}>
                {file!.name}
              </Typography>
            </Box>
            <Typography sx={{ color: "#314362", marginLeft: "0.5rem" }} fontWeight={"300"}>
              {bytesToMB(file!.size)}
            </Typography>
          </Box>
        )}
        {file && (
          <Box>
            <Typography sx={{ color: "#D4D5D6", marginBottom: "0.5rem" }} fontWeight={"600"}>
              Compression Level
            </Typography>
            <ButtonGroup>
              {compressionArray.map((item) => (
                <CompressionLevelButton key={item.id} item={item} />
              ))}
            </ButtonGroup>
          </Box>
        )}

        {/* <MuiFileInput
          fullWidth
          value={file}
          error={errors.length > 0}
          onChange={setFile}
          inputProps={{ accept: ".pdf, .docx" }}
          helperText={
            <>
              {errors.map((_error) => (
                <div>{_error}</div>
              ))}
            </>
          }
          InputProps={{
            startAdornment: !file ? (
              <InputAdornment position="start">
                <UploadFile /> Click or Drag Upload File Here
              </InputAdornment>
            ) : undefined,
          }}
        /> */}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft="1rem">
        <Box display="flex" alignItems="center">
          <Button
            role={undefined}
            variant="text"
            disabled={file !== null}
            tabIndex={-1}
            startIcon={<UploadFile />}
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            Upload
            {/* Hidden file input */}
            <Input
              type="file"
              inputProps={{ accept: ".pdf, .docx, .txt" }}
              inputRef={inputRef} // Bind input to the ref
              onChange={(event) => {
                const file = (event.target as HTMLInputElement).files?.[0];
                console.log(file);

                if (file) {
                  setFile(file);
                }
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
              }}
              style={{ display: "none" }} // Hide the input element
            />
          </Button>
          <CardTooltip
            placement="right"
            tooltipContent={
              <CommonTooltipContent
                header="Document Summary"
                desc="Supported document formats include PDF, DOC, and TXT. Document size must not exceed 5 Mb."
              />
            }
          />
        </Box>
        <LoadingButton
          disableElevation
          disabled={!file}
          variant="contained"
          aria-label="search"
          loading={loading}
          onClick={callAPI}
          sx={{ padding: "8px", margin: "4px", minWidth: "unset", color: grey[700] }}
        >
          <ArrowForward />
        </LoadingButton>
      </Box>
      <UploadSettingsModal />
    </Paper>
  );
}
