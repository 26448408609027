import { AutoAwesomeOutlined, QuestionAnswerOutlined } from "@mui/icons-material";
import { Box, Button, SxProps, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { ChatInteraction, FocusedInteraction } from "../models";
import { scrollQuestionIntoView } from "./StudyChat";

const ThumbStyle: SxProps = {
  verticalAlign: "sub",
  fill: "rgba(66, 84, 102, 0.2)",
  cursor: "pointer",
  "&:hover": {
    fill: "rgba(66, 84, 102, 0.7)",
  },
  "&.selected": {
    fill: "rgba(19, 41, 75, 1)",
  },
};

interface ChatSessionOutlineProps {
  onClickNewThread: () => void;
  messages: ChatInteraction[];
}

export default function ChatSessionOutline({ messages, onClickNewThread }: ChatSessionOutlineProps) {
  const [focusedQuestion] = useAtom(FocusedInteraction);

  return (
    <>
      <Box>
        <QuestionAnswerOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
        <Typography variant="h6" color={"#13294B"} display="inline-block">
          Current Chat
        </Typography>
      </Box>

      <Box
        sx={{
          overflowY: "scroll",
          pr: "2em",
          flex: 1,
          minHeight: 0,
          "& .thread-question": {
            marginBottom: "1em",
            color: "blue",
            cursor: "pointer",

            "&.focused": { borderLeft: "2px solid navy", color: "navy", pl: "0.5em" },
          },
        }}
      >
        {messages.map((response, i) => {
          const classes = ["thread-question"];
          if (focusedQuestion === response) {
            classes.push("focused");
          }

          // NOTE: This only works because ${i} is the same here that it is in <SessionThread />
          const anchorId = `${String(response.user_chat_session)}-${i}`;
          return (
            <Box key={i} className={classes.join(" ")} onClick={() => scrollQuestionIntoView(anchorId)}>
              {response.user_query}
            </Box>
          );
        })}
      </Box>

      <Box sx={{ paddingRight: "2em", textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{ color: "#13294B" }}
          startIcon={<AutoAwesomeOutlined sx={{ fill: "#13294B" }} />}
          onClick={() => onClickNewThread()}
        >
          Start a New Question Thread
        </Button>
      </Box>
    </>
  );
}
