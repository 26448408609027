import { useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import * as DeepchatApi from "../../api/deepchat";
import useUserContext from "../../components/Authorization/useUserContext";
import { WebSocketChatResponse } from "../../contexts/useDeepchat";
import { SalesDataStateAtom } from "./SalesDataState";

const DEEPCHAT_WS_URI = import.meta.env.VITE_APP_DEEPCHAT_WS_URI;

export const useSalesDataStore = () => {
  const user = useUserContext();

  // Atoms
  const [, setState] = useAtom(SalesDataStateAtom);

  const formatChatUrl = (chatAuthCode: string) => {
    return `${DEEPCHAT_WS_URI}?authCode=${chatAuthCode}`;
  };

  // WebSocket
  const getChartUrl = useCallback(async () => {
    const response = await DeepchatApi.getToken(user);
    return formatChatUrl(response.auth_code);
  }, []);

  const getWebSocket = useCallback(() => useWebSocket<WebSocketChatResponse<unknown>>(getChartUrl), [0]);
  const { lastJsonMessage, sendJsonMessage, readyState } = getWebSocket();

  // Collect messages from the open WebSocket
  useEffect(() => {
    // This logic is different than how DeepChat works because in DeepChat we have a "updating" Answer message
    // that we have to continually sync with the latest message, whereas here in "Structured Data" every message is atomic
    if (lastJsonMessage?.type) {
      setState((_) => {
        const next = { ..._ };
        next.messages = [...next.messages, lastJsonMessage];
        return next;
      });
    }
  }, [lastJsonMessage]);

  const clearSession = () => {
    setState((_) => ({ messages: [], loading: false }));
  };

  const setLoading = (loading: boolean) => {
    setState((_) => ({ ..._, loading }));
  };

  return {
    lastJsonMessage,
    sendJsonMessage,
    readyState,

    clearSession,
    setLoading,
  };
};

const SocketConnectionStatus = {
  [ReadyState.CONNECTING]: "Connecting",
  [ReadyState.OPEN]: "Open",
  [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  [ReadyState.CLOSING]: "Closing",
  [ReadyState.CLOSED]: "Closed",
};
