import { Box, Button, Input, Stack, Typography } from "@mui/material";
import { useState } from "react";
import useUserContext from "../Authorization/useUserContext";
import useDeepchat from "../../contexts/useDeepchat";
import { useDeepChatSocketContext } from "../../contexts/DeepChatSocketContext";
import { useSalesDataStore } from "./useSalesDataStore";

export default function SalesDataAsk() {
  const SalesData = useSalesDataStore();
  const Deepchat = useDeepchat();
  const DeepChatSocket = useDeepChatSocketContext();

  const [input, setInput] = useState("");

  const submitSearch = async (question: string) => {
    SalesData.clearSession();
    SalesData.setLoading(true);
    Deepchat.askSalesDataStreaming(DeepChatSocket.sendJsonMessage, question);
  };

  // Top 5 growth accounts for last quarter for Ocrevus?
  // What're the top 5 growing product molecule Ocrevus accounts of q3 vs q2?
  // What was ocrevus's market share in july of last year?
  // how many more units of tnkase did we sell last quarter vs the one before?

  return (
    <>
      <Box flex={1} minHeight="100%" marginX="auto" maxWidth="48rem">
        <Stack
          className="QuestionInput"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ minHeight: "100%" }}
        >
          <Typography variant="h3" fontWeight="bold" color={"rgb(0, 48, 135)"}>
            Deepchat
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {/* <DeepchatNavigationTabs /> */}
            {/* <QuestionInput placeholder="Ask a related follow-up question…" hideEnhanceButton={true} /> */}
            <Input
              value={input}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInput(event.target.value);
              }}
            />
            <Button onClick={() => submitSearch(input)}>Go</Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
